import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModuloAdministracionService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl + '/administration';

  apiGetSedesNivelesGrados  = (params?: any)=> this.http.get(this.apiUrl+'/academico/sedes-niveles-grados', {params}).pipe(map((r: any) => r.data));


  apiGetSedes    = (params?: any)=> this.http.get(this.apiUrl+'/sede', {params}).pipe(map((r: any) => r.data));
  getNiveles    = (params?: any)=> this.http.get(this.apiUrl+'/nivel', {params}).pipe(map((r: any) => r.data));

  // apiGetGrupos    = (params?: any)          => this.http.get    (`${this.apiUrl}/grupo`, {params}).pipe(map((r: any) => r.data));
  // apiCreateGrupo  = (data: any)             => this.http.post   (`${this.apiUrl}/grupo`, data);
  // apiUpdateGrupo  = (id: number, data: any) => this.http.put    (`${this.apiUrl}/grupo/${id}`, data);
  // apiDeleteGrupo  = (id: number)            => this.http.delete (`${this.apiUrl}/grupo/${id}`);


  getTipoPlanes = (params?: any)=> this.http.get(this.apiUrl+'/acad-tipo-plan', {params}).pipe(map((r: any) => r.data));
  apiGetPeriodos      = (params?: any)=> this.http.get(this.apiUrl+'/periodo', {params}).pipe(map((r: any) => r.data));


  // apiGetPlanEstudios    = (params?: any)          => this.http.get    (`${this.apiUrl}/plan-estudio`, {params}).pipe(map((r: any) => r.data));
  // apiCreatePlanEstudio  = (data: any)             => this.http.post   (`${this.apiUrl}/plan-estudio`, data);
  // apiUpdatePlanEstudio  = (id: number, data: any) => this.http.put    (`${this.apiUrl}/plan-estudio/${id}`, data);
  // apiDeletePlanEstudio  = (id: number)            => this.http.delete (`${this.apiUrl}/plan-estudio/${id}`);

  // apiGetMatriculaPlanCostos    = (params?: any)           => this.http.get    (`${this.apiUrl}/matricula-plan-costo`, {params}).pipe(map((r: any) => r.data));
  // apiCreateMatriculaPlanCosto  = (data: any)              => this.http.post   (`${this.apiUrl}/matricula-plan-costo`, data);
  // apiUpdateMatriculaPlanCosto  = (id: number, data: any)  => this.http.put    (`${this.apiUrl}/matricula-plan-costo/${id}`, data);
  // apiDeleteMatriculaPlanCosto  = (id: number)             => this.http.delete (`${this.apiUrl}/matricula-plan-costo/${id}`);

  // apiGetMatriculaCostos     = (params?: any)  => this.http.get    (`${this.apiUrl}/matricula-costo`, {params}).pipe(map((r: any) => r.data));
  // apiSaveMatriculaCosto     = (data: any)     => this.http.post   (`${this.apiUrl}/matricula-costo-save/`, data);
  // apiDeleteMatriculaCosto   = (id: number)    => this.http.delete (`${this.apiUrl}/matricula-costo/${id}`);

}
