import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {

  formDataLogin: any = {};

  constructor(
    private router: Router,
    private loginService: LoginService,
  ){}

  formLogin = [
    {
      type: 'text',
      name: 'Usuario',
      placeholder: 'Ingrese su usuario'
    },
    {
      type: 'password',
      name: 'Contraseña',
      placeholder: 'Ingrese su contraseña'
    }
  ];

  clickLogin(){
    console.log('formDataLogin: ', this.formDataLogin);
    const params = {
      usuario: this.formDataLogin.Usuario,
      password: this.formDataLogin.Contraseña,
    };
    this.loginService.login(params).subscribe((data)=>{
      console.log('RESPUESTA LOGIN: ', data)
      if (data.access_token) {
        localStorage.setItem('authToken', data.access_token);
        this.router.navigate(['/menu']);
      }
    });
  }

}

