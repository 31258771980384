<div class="esv-page">
  <div class="page-title">
    Reporte de Mensualidades por Grupo
  </div>
  <div class="header">
    <div class="element-section">
      <div class="element-section-title">Filtros de búsqueda</div>
    </div>

    <div class="esv-filters-list">

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Tipo de Plan</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedPlanTipo" (selectionChange)="applyFilters()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let tipoPlan of listPlanTipos" [value]="tipoPlan.id">{{tipoPlan.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Periodo</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedPeriodo" (selectionChange)="applyFilters()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let periodo of listPeriodos" [value]="periodo.id">{{periodo.codigo}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Sede</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedSede" (selectionChange)="onSedeChange()" >
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let sede of listSedes" [value]="sede.id">{{sede.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Nivel</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedNivel" (selectionChange)="onNivelChange()" >
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let nivel of listNiveles" [value]="nivel.id">{{nivel.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Grado</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic"  >
          <mat-select [(value)]="selectedGrado" (selectionChange)="applyFilters()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let grado of listGrados" [value]="grado.id">{{grado.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Grupo</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic"  >
          <mat-select [(value)]="selectedGrupo" (selectionChange)="applyFilters()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let grupo of listGrupos" [value]="grupo.id">{{grupo.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <button class="esv-button search as-filter-select" (click)="getReporte()"> <mat-icon>search</mat-icon> Generar reporte </button>
      </div>

    </div>

  </div>

  <div class="body">

    <div class="element-section">
      <div class="element-section-title">Resultados</div>
    </div>

  </div>

</div>
