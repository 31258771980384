<div class="ticket-container">
  <div class="ticket-logo">
    <img src="/assets/Logo-SJ.png" alt="Logo">
  </div>
  <div class="ticket-owner">
    Colegio San José Juliaca
  </div>
  <div class="ticket-details">
    R.U.C. 20123456789 <br>
    Jr. Gonzales Prada 123 <br>
    Juliaca - Puno
  </div>

  <div class="ticket-divider"></div>

  <div class="ticket-title">
    TICKET N°: 01-14-0001<br>
  </div>
  <div class="ticket-serial">
    {{ fechaHoraActual | date: 'dd/MM/yyyy HH:mm:ss' }}
  </div>

  <div class="ticket-divider"></div>

  <div class="overflow-view">
    <div class="invoice-body">
      <table>
        <thead>
          <tr>
            <td>Nombre</td>
            <td>Tipo</td>
            <!-- <td>Monto Inicial</td>
            <td>Monto final</td>
            <<td>Fecha de Pago inicial</td> -->
            <td>Fecha de Pago final</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>M1</td>
            <td>WMatricula</td>
            <!-- <td>S/. </td>
            <td>S/. </td>
            <td>10/11/2024</td> -->
            <td>10/11/2024</td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <div class="invoice-body-bottom">
            <div class="invoice-body-info-item border-bottom">
              <div class="info-item-td text-end ">Total a Pagar:</div>
              <div class="info-item-td text-end">$1500.00</div>
            </div>
            <div class="invoice-body-info-item border-bottom">
              <div class="info-item-td text-end ">Saldo Actual:</div>
              <div class="info-item-td text-end">$150.00</div>
            </div>
            <div class="invoice-body-info-item">
              <div class="info-item-td text-end ">Saldo a Favor:</div>
              <div class="info-item-td text-end">$1650.00</div>
            </div>
          </div>
        </tbody>
      </table>
    </div>
  </div>

  <div class="ticket-divider"></div>

  <div class="ticket-details">
    --------------------------------------- <br>
    Le ha atendido ... <br>
    --------------------------------------- <br>
    GRACIAS POR SU PAGO <br>
    wwww.sanjosejuliaca.com <br>
    ---------------------------------------
    <div class="ticket-logo"><img src="/assets/barcode.webp" alt="Logo"></div>
  </div>



  <div class="ticket-content">
    <div class="ticket-content-detail"></div>
    <div class="ticket-content-total"></div>
  </div>

</div>



<!-- <div class="ticket" id="print-area">
  <div class="ticket-header">
    <h2>Tienda XYZ</h2>
    <p>Dirección: Calle Falsa 123</p>
    <p>Teléfono: (01) 123-4567</p>
  </div>
  <div class="ticket-body">
    <p class="ticket-date">Fecha: 05/12/2024</p>
    <table>
      <thead>
        <tr>
          <th>Descripción</th>
          <th>Cant</th>
          <th>Precio</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Producto 1</td>
          <td>2</td>
          <td>S/ 50.00</td>
          <td>S/ 100.00</td>
        </tr>
        <tr>
          <td>Producto 2</td>
          <td>1</td>
          <td>S/ 100.00</td>
          <td>S/ 100.00</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="ticket-footer">
    <p>Subtotal: S/ 200.00</p>
    <p>IGV (18%): S/ 36.00</p>
    <p>Total: S/ 236.00</p>
    <p class="ticket-thanks">¡Gracias por su compra!</p>
  </div>
</div> -->
