import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MatReportesMatriculadosService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl + '/matricula/reportes/matriculados';

  apiGetEstudiantesMatriculados = (params?: any)  => this.http.get    (`${this.apiUrl}/get-estudiantes-matriculados`, {params});

}
