import { Component } from '@angular/core';
import { ModuloMatriculaService } from '../../../modulo-matricula.service';
import { PageEvent } from '@angular/material/paginator';
import { ModuloAdministracionService } from 'src/app/pages/modulo-administracion/modulo-administracion.service';
import { AdministrationSharedService } from 'src/app/shared-services/administration-shared.service';
import { MatReportesMatriculadosService } from '../../../services/reportes/matriculados/mat-reportes-matriculados.service';

@Component({
  selector: 'app-matri-estudiantes-matriculados-list',
  templateUrl: './matri-estudiantes-matriculados-list.component.html',
  styleUrl: './matri-estudiantes-matriculados-list.component.css'
})
export class MatriEstudiantesMatriculadosListComponent {

  isLoading : boolean = false;

  listSedes     : any[] = [];
  listNiveles   : any[] = [];
  listGrados    : any[] = [];
  listPeriodos  : any[] = [];
  listPlanTipos : any[] = [];

  searchTerm        : number | string = '';
  selectedSede      : number | string = '';
  selectedNivel     : number | string = '';
  selectedGrado     : number | string = '';
  selectedPeriodo   : number | string = '';
  selectedPlanTipo  : number | string = '';
  selectedEstado    : number | string = '1';

  pageList  : any[] = [];
  pageTotal : number = 0;
  pageSize  : number = 5;
  pageIndex : number = 0;

  constructor (
    private administrationSharedService     : AdministrationSharedService,
    private matReportesMatriculadosService  : MatReportesMatriculadosService,
    // private moduloMatriculaService      : ModuloMatriculaService,
    // private moduloAdministracionService : ModuloAdministracionService,
  ) { }

  ngOnInit(){
    this.getSedesNivelesGrados();
    this.getPeriodosTipoPlanes();
    this.getEstudiantesMatriculados();
  }

  getSedesNivelesGrados() {
    let params = { };
    this.administrationSharedService.apiGetSedesNivelesGrados(params)
    .subscribe((resp: any) => {
      this.listSedes = resp.data;
    });
  }

  getPeriodosTipoPlanes() {
    let params = { 'activo': true };
    this.administrationSharedService.apiGetPeriodosPlanTipos(params)
      .subscribe((resp: any) => {
        this.listPeriodos = resp.data;
      });
  }

  getEstudiantesMatriculados(): void {
    let params = {
      search_term   : this.searchTerm,
      estado        : this.selectedEstado,
      id_sede       : this.selectedSede,
      id_nivel      : this.selectedNivel,
      id_grado      : this.selectedGrado,
      id_periodo    : this.selectedPeriodo,
      id_plan_tipo  : this.selectedPlanTipo,
      page_size     : this.pageSize,
      page_index    : this.pageIndex,
    };

    this.isLoading = true;
    this.matReportesMatriculadosService.apiGetEstudiantesMatriculados(params)
      .subscribe((response: any) => {
        this.isLoading  = false;
        this.pageList   = response.data.estudiantes;
        this.pageTotal  = response.data.total;
      }
    );

  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  onSedeChange() {
    this.selectedNivel = '';
    this.selectedGrado = '';
    this.setNiveles();
    this.getEstudiantesMatriculados();
  }

  onNivelChange() {
    this.selectedGrado = '';
    this.setGrados();
    this.getEstudiantesMatriculados();
  }

  onPeriodoChange() {
    this.selectedPlanTipo = '';
    this.setPlanTipos();
    this.getEstudiantesMatriculados();
  }

  applyFilters(){
    this.getEstudiantesMatriculados();
  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  setNiveles() {
    if (this.selectedSede) {
      let sede = this.listSedes.find(n => n.id === this.selectedSede);
      if (sede) {
        this.listNiveles = sede.niveles;
      }
    } else {
      this.listNiveles = [];
    }
    this.listGrados = [];
  }

  setGrados() {
    if (this.selectedNivel) {
      let nivel = this.listNiveles.find(n => n.id === this.selectedNivel);
      if (nivel) {
        this.listGrados = nivel.grados;
      }
    } else {
      this.listGrados = [];
    }
  }

  setPlanTipos() {
    if (this.selectedPeriodo) {
      let periodo = this.listPeriodos.find(n => n.id === this.selectedPeriodo);
      if (periodo) {
        this.listPlanTipos = periodo.plan_tipos;
      }
    } else {
      this.listPlanTipos = [];
    }
  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  // openAnularMatricula(id_estudiante: number, id_grupo: number){
  //   let params = {
  //     id_estudiante: id_estudiante,
  //     id_grupo: id_grupo,
  //   };

  //   this.moduloMatriculaService.apiAnularMatricula(params)
  //     .subscribe((response: any) => {
  //       this.getEstudiantesMatriculados();
  //     }
  //   );
  // }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  buildFeaturesArray(planEstudio: any) {
    return [
      { key: 'Sede',    value: this.getNombreSede(planEstudio.id_sede) },
      { key: 'Nivel',   value: this.getNombreNivel(planEstudio.id_sede, planEstudio.id_nivel) },
      { key: 'Grado',   value: this.getNombreGrado(planEstudio.id_sede, planEstudio.id_nivel, planEstudio.id_grado) },
      { key: 'Periodo', value: this.getNombrePeriodo(planEstudio.id_periodo) },
      { key: 'Tipo',    value: this.getNombreTipoPlan(planEstudio.id_periodo, planEstudio.id_plan_tipo) },
    ];
  }

  getNombreById(list: any[], id: number, defaultValue: string = 'Todos'): string {
    const item = list.find(el => el.id === id);
    return item ? item['nombre'] : defaultValue;
  }

  getNombreSede(idSede: number): string {
    return this.getNombreById(this.listSedes, idSede);
  }

  getNombreNivel(idSede: number, idNivel: number): string {
    const sede = this.listSedes.find((s: { id: number; }) => s.id === idSede);
    return sede ? this.getNombreById(sede.niveles, idNivel) : 'Todos';
  }

  getNombreGrado(idSede: number, idNivel: number, idGrado: number): string {
    const sede = this.listSedes.find((s: { id: number; }) => s.id === idSede);
    const nivel = sede?.niveles.find((n: { id: number; }) => n.id === idNivel);
    return nivel ? this.getNombreById(nivel.grados, idGrado) : 'Todos';
  }

  getNombrePeriodo(id: number): string {
    const periodo = this.listPeriodos.find(p => p.id === id);
    return periodo ? periodo.codigo : '';
  }

  getNombreTipoPlan(idPeriodo: number, id: number): string {
    const periodo = this.listPeriodos.find(p => p.id === idPeriodo);
    const tipoPlan = periodo.plan_tipos.find((tp: { id: number; }) => tp.id === id);
    return tipoPlan ? tipoPlan.nombre : '';
  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  pageEvent(event: PageEvent) {
    this.pageSize   = event.pageSize;
    this.pageIndex  = event.pageIndex;
    this.getEstudiantesMatriculados();
  }

}

export interface Usuario {
  id:                  number;
  name:                string;
  name_2:              string;
  lastname_1:          string;
  lastname_2:          string;
  email:               string;
  usuario:             string;
  documento_identidad: string;
  tipo_documento:      number;
  domicilio:           string;
  celular:             null;
  nombres:             string;
}
