<div class="esv-page">

  <sv-page-title
    [pageTitle]="'Matricular a Estudiante'"
    [showGoBack]="true"
    [showReload]="true">
  </sv-page-title>

  <div class="header">

    <div class="element-section">
      <div class="element-section-title">Lista de Estudiantes</div>
      <div class="row-align-center">
        <input class="search-input" type="text" [(ngModel)]="searchTerm" [value]="searchTerm" placeholder="Buscar..." (keydown.enter)="getEstudiantes()">
        <button class="esv-button search" (click)="getEstudiantes()"> <mat-icon>search</mat-icon> Buscar </button>
        <button class="esv-button primary" (click)="openNuevoEstudiante()"> <mat-icon>add</mat-icon> Nuevo Estudiante</button>
      </div>
    </div>

  </div>

  <div class="body">

    <div *ngIf="isLoading">
      <app-sv-skeleton-card-list
        [numberCards]="pageSize"
        [numberChildren]="2"
        [numberChips]="0"
        [childrenCircle]="false">
      </app-sv-skeleton-card-list>
    </div>

    <div *ngIf="!isLoading">
      <div class="esv-card-list">
        <sv-card-list
          *ngFor="let usuario of pageList"
          [titulo]="usuario.nombre_completo"
          [menuItems]="menuItems"
          [descripcionArray]="[
            { key: 'Usuario'  , value: usuario.usuario},
            { key: 'DNI'      , value: usuario.documento_identidad},
            { key: 'Correo'   , value: usuario.email},
            { key: 'Celular'  , value: usuario.celular ?? 'No registrado'},
            { key: 'Domicilio', value: usuario.domicilio},
          ]"
          (actionClicked)="onMenuAction($event, usuario)">
        </sv-card-list>
      </div>
    </div>

    <mat-paginator *ngIf="!isLoading" [length]="pageTotal" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 20]" (page)="pageEvent($event)">
    </mat-paginator>

  </div>

</div>


