import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/core/not-found/not-found.component';
import { MatriMainComponent } from './matri-main.component';
import { MatriEstudiantesListComponent } from './components/estudiante/matricula/matri-estudiantes-list.component';
import { MatriEstudiantesMatriculadosListComponent } from './components/reporte/matriculados/matri-estudiantes-matriculados-list.component';
import { MatriculaProcesoMainComponent } from './components/matricula-proceso-main/matricula-proceso-main.component';

const routes: Routes = [{
  path: '',
  component: MatriMainComponent,
  children: [
    {
      path: 'estudiantes',
      children: [
        {
          path: 'matricula',
          component: MatriEstudiantesListComponent,
        },
        {
          path: 'proceso/:id',
          component: MatriculaProcesoMainComponent,
        },
        {
          path: '',
          redirectTo: 'matricula',
          pathMatch: 'full'
        },
      ],
    },
    {
      path: 'reportes',
      children: [
        {
          path: 'matriculados',
          component: MatriEstudiantesMatriculadosListComponent,
        },
        {
          path: '',
          redirectTo: 'matriculados',
          pathMatch: 'full'
        },
      ],
    },




    // {
    //   path: 'estudiantes/nuevo',
    //   // component: MatriProcesoNuevoEstudianteComponent,
    //   component: MatriProcesoNuevoEstudiantePasosComponent,
    // },
    // {
    //   path: 'estudiantes/:id/proceso',
    //   component: MatriProcesoMainComponent,
    // },
    {
      path: 'estudiante/proceso/:id',
      // component: MatriProcesoMainComponent,
      component: MatriculaProcesoMainComponent,
    },
    {
      path: '',
      redirectTo: 'estudiantes/matricula',
      pathMatch: 'full'
    },
    {
      path: '**',
      component: NotFoundComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModuloMatriculaRoutingModule { }
