import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Usuario } from './components/usuario/conf-usuarios-list/conf-usuarios-list.component';
import { ModuloModel } from './components/modulo/conf-modulos-list/conf-modulos-list.component';
import { RolModel } from './components/rol/conf-roles-list/conf-roles-list.component';

@Injectable({
  providedIn: 'root'
})
export class ModuloConfiguracionService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl;

  apiUrlGroup = {
    programasCurriculares: environment.apiUrl + '/configuration/programas-curriculares',
  };

  ///////////////////////////////////////////////////////////////////////////
  /////////////////////////    CONFIGURACIÓN DE ROLES
  ///////////////////////////////////////////////////////////////////////////

  apiGetRoles   = ()=> this.http.get(this.apiUrl+'/configuration/rol').pipe(map((r: any) => r.data));
  apiCreateRol  = (rol: RolModel): Observable<RolModel> => this.http.post<RolModel>(`${this.apiUrl}/configuration/rol`, rol);
  apiUpdateRol  = (id: number, rol: RolModel): Observable<RolModel> => this.http.put<RolModel>(`${this.apiUrl}/configuration/rol/${id}`, rol);
  apiDeleteRol  = (id: number) => this.http.delete(`${this.apiUrl}/configuration/rol/${id}`);

  apiGetUsersWithRole   = (id_rol: number): Observable<any> => this.http.get<any>(`${this.apiUrl}/configuration/users-with-role`, { params: { id_rol } });
  apiSearchUsersNoRole  = (searchTerm: string, id_rol: number): Observable<any> => this.http.get<any>(`${this.apiUrl}/configuration/search-users-no-role`, { params: { search: searchTerm, id_rol: id_rol } });
  apiAssignUserToRole   = (id_user: number, id_rol: number): Observable<any> => this.http.post<any>(`${this.apiUrl}/configuration/user-asignar-rol`, { id_user, id_rol, activo: 1 });
  apiRemoveUserFromRole = (id_user: number, id_rol: number): Observable<any> => this.http.post<any>(`${this.apiUrl}/configuration/user-asignar-rol`, { id_user, id_rol, activo: 0 });

  getModulosByRol(id_rol: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/configuration/rol/${id_rol}/modulos`);
  }
  assignModulosToRol(id_rol: number, modulos: any[]): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/configuration/rol/${id_rol}/modulos`, { id_rol, modulos });
  }

  ///////////////////////////////////////////////////////////////////////////
  /////////////////////////    CONFIGURACIÓN DE MÓDULOS
  ///////////////////////////////////////////////////////////////////////////

  apiGetModulos   = ()=> this.http.get(this.apiUrl+'/configuration/modulo').pipe(map((r: any) => r.data));
  apiCreateModulo = (modulo: ModuloModel): Observable<ModuloModel> => this.http.post<ModuloModel>(`${this.apiUrl}/configuration/modulo`, modulo);
  apiUpdateModulo = (id: number, modulo: ModuloModel): Observable<ModuloModel> => this.http.put<ModuloModel>(`${this.apiUrl}/configuration/modulo/${id}`, modulo);
  apiDeleteModulo = (id: number) => this.http.delete(`${this.apiUrl}/configuration/modulo/${id}`);

  ///////////////////////////////////////////////////////////////////////////
  /////////////////////////    CONFIGURACIÓN DE USUARIOS
  ///////////////////////////////////////////////////////////////////////////

  apiGetUsuarios(pageIndex: number, pageSize: number, searchTerm: string): Observable<any> {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('pageSize', pageSize.toString())
      .set('search', searchTerm);

    return this.http.get<any>(`${this.apiUrl}/configuration/all-users`, { params });
  }
  apiCreateUsuario = (usuario: Usuario): Observable<Usuario> => this.http.post<Usuario>(`${this.apiUrl}/configuration/create-user`, usuario);
  apiUpdateUsuario = (id: number, usuario: Usuario): Observable<Usuario> => this.http.put<Usuario>(`${this.apiUrl}/configuration/update-user/${id}`, usuario);
  apiDeleteUsuario = (id: number) => this.http.delete(`${this.apiUrl}/configuration/delete-user/${id}`);

  ///////////////////////////////////////////////////////////////////////////
  /////////////////////////    CONFIGURACIÓN DE PROGRAMAS CURRICULARES
  ///////////////////////////////////////////////////////////////////////////

  apiGetProgramasCurriculares     = (params?: any)          => this.http.get    (this.apiUrlGroup.programasCurriculares+'/programas'     , {params}) .pipe(map((r: any) => r.data));

  apiGetGradosCurriculares        = (params?: any)          => this.http.get    (this.apiUrlGroup.programasCurriculares+'/grados'        , {params}) .pipe(map((r: any) => r.data));

  apiGetAreasCurriculares         = (params?: any)          => this.http.get    (this.apiUrlGroup.programasCurriculares+'/areas'         , {params}) .pipe(map((r: any) => r.data));
  apiCreateAreaCurricular         = (data: any)             => this.http.post   (this.apiUrlGroup.programasCurriculares+'/areas'         , data)     .pipe(map((r: any) => r.data));
  apiUpdateAreaCurricular         = (id: number, data: any) => this.http.put    (this.apiUrlGroup.programasCurriculares+'/areas/'        + id, data) .pipe(map((r: any) => r.data));
  apiDeleteAreaCurricular         = (id: any)               => this.http.delete (this.apiUrlGroup.programasCurriculares+'/areas/'        + id);

  apiGetCompetenciasCurriculares  = (params?: any)          => this.http.get    (this.apiUrlGroup.programasCurriculares+'/competencias'  , {params}) .pipe(map((r: any) => r.data));
  apiCreateCompetenciaCurricular  = (data: any)             => this.http.post   (this.apiUrlGroup.programasCurriculares+'/competencias'  , data)     .pipe(map((r: any) => r.data));
  apiUpdateCompetenciaCurricular  = (id: number, data: any) => this.http.put    (this.apiUrlGroup.programasCurriculares+'/competencias/' + id, data) .pipe(map((r: any) => r.data));
  apiDeleteCompetenciaCurricular  = (id: any)               => this.http.delete (this.apiUrlGroup.programasCurriculares+'/competencias/' + id);

  apiGetCapacidadesCurriculares   = (params?: any)          => this.http.get    (this.apiUrlGroup.programasCurriculares+'/capacidades'   , {params}) .pipe(map((r: any) => r.data));
  apiCreateCapacidadesCurricular  = (data: any)             => this.http.post   (this.apiUrlGroup.programasCurriculares+'/capacidades'   , data)     .pipe(map((r: any) => r.data));
  apiUpdateCapacidadesCurricular  = (id: number, data: any) => this.http.put    (this.apiUrlGroup.programasCurriculares+'/capacidades/'  + id, data) .pipe(map((r: any) => r.data));
  apiDeleteCapacidadesCurricular  = (id: any)               => this.http.delete (this.apiUrlGroup.programasCurriculares+'/capacidades/'  + id);

  apiGetDesempenyosCurriculares   = (params?: any)          => this.http.get    (this.apiUrlGroup.programasCurriculares+'/desempenyos'   , {params}) .pipe(map((r: any) => r.data));
  apiCreateDesempenyosCurricular  = (data: any)             => this.http.post   (this.apiUrlGroup.programasCurriculares+'/desempenyos'   , data)     .pipe(map((r: any) => r.data));
  apiUpdateDesempenyosCurricular  = (id: number, data: any) => this.http.put    (this.apiUrlGroup.programasCurriculares+'/desempenyos/'  + id, data) .pipe(map((r: any) => r.data));
  apiDeleteDesempenyosCurricular  = (id: any)               => this.http.delete (this.apiUrlGroup.programasCurriculares+'/desempenyos/'  + id);

  ///////////////////////////////////////////////////////////////////////////
  /////////////////////////    CONFIGURACIÓN DE SOPORTE
  ///////////////////////////////////////////////////////////////////////////

  apiGetSecretarias           = (params?: any)          => this.http.get    (this.apiUrl+'/configuration/soporte/secretaria'          , {params}) .pipe(map((r: any) => r.data));
  apiCreateSecretaria         = (data: any)             => this.http.post   (this.apiUrl+'/configuration/soporte/secretaria'          , data)     .pipe(map((r: any) => r.data));
  apiUpdateSecretaria         = (id: number, data: any) => this.http.put    (this.apiUrl+'/configuration/soporte/secretaria/'         + id, data) .pipe(map((r: any) => r.data));
  apiDeleteSecretaria         = (id: any)               => this.http.delete (this.apiUrl+'/configuration/soporte/secretaria/'         + id);
  apiSearchSecretariaPersonas = (params?: any)          => this.http.get    (this.apiUrl+'/configuration/soporte/secretaria-personas' , {params}) .pipe(map((r: any) => r.data));

  apiGetSecuencias    = (params?: any)          => this.http.get    (this.apiUrl+'/configuration/soporte/secuencia'   , {params}) .pipe(map((r: any) => r.data));
  apiCreateSecuencia  = (data: any)             => this.http.post   (this.apiUrl+'/configuration/soporte/secuencia'   , data)     .pipe(map((r: any) => r.data));
  apiUpdateSecuencia  = (id: number, data: any) => this.http.put    (this.apiUrl+'/configuration/soporte/secuencia/'  + id, data) .pipe(map((r: any) => r.data));
  apiDeleteSecuencia  = (id: any)               => this.http.delete (this.apiUrl+'/configuration/soporte/secuencia/'  + id);

  apiSetSecretariaSecuencia     = (data: any)   => this.http.post   (this.apiUrl+'/configuration/soporte/secretaria-set-secuencia'    , data) .pipe(map((r: any) => r.data));
  apiRemoveSecretariaSecuencia  = (data: any)   => this.http.post   (this.apiUrl+'/configuration/soporte/secretaria-remove-secuencia' , data) .pipe(map((r: any) => r.data));


}
