<div mat-dialog-title class="dialog-title">Eliminar item</div>

<div mat-dialog-content class="text-description">
  <b>¿Está seguro de eliminar este elemento?</b>
  <br>
  Esta acción no se puede deshacer
</div>

<div mat-dialog-actions class="my-3 mat-dialog-actions">
  <button class="custom-action-button transparent" type="button" (click)="onCancel()">Cancelar</button>
  <button class="custom-action-button primary" type="submit" (click)="onDelete()">Eliminar</button>
</div>
