<div class="esv-card-list">
  <mat-card class="custom-card skeleton-card" *ngFor="let card of [].constructor(numberCards)">
    <mat-card-header>
      <div class="grupo-column">
        <div class="skeleton-loader skeleton-text-half"></div>
      </div>
    </mat-card-header>

    <mat-card-content>

      <div *ngIf="numberChips > 0" class="chip-set">
        <div *ngFor="let chip of [].constructor(numberChips)" class="chip skeleton-loader"></div>
      </div>

      <div class="grupo-container skeleton" *ngFor="let child of [].constructor(numberChildren)">
        <div *ngIf="childrenCircle" class="grupo-circle skeleton-loader"></div>

        <div class="grupo-column">
          <div class="skeleton-loader skeleton-text"></div>
          <div class="skeleton-loader skeleton-text-half"></div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
