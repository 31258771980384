import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuloMatriculaRoutingModule } from './modulo-matricula-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule, MatCardSubtitle } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';

import { MatriEstudiantesListComponent } from './components/estudiante/matricula/matri-estudiantes-list.component';
import { MatriMainComponent } from './matri-main.component';
import { MatriProcesoMainComponent } from './components/matri-proceso-main/matri-proceso-main.component';
import { MatNativeDateModule, MatOptionModule, NativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDivider } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { SvComponentsModule } from 'src/app/shared-components/sv-components.module';
import { MatriEstudiantesMatriculadosListComponent } from './components/reporte/matriculados/matri-estudiantes-matriculados-list.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatriProcesoResumenMatriculaComponent } from './components/matri-proceso-resumen-matricula/matri-proceso-resumen-matricula.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatriProcesoMainPaso1Component } from './components/matri-proceso-main-paso1/matri-proceso-main-paso1.component';
import { MatriProcesoMainPaso2Component } from './components/matri-proceso-main-paso2/matri-proceso-main-paso2.component';
import { MatriProcesoMainPaso3Component } from './components/matri-proceso-main-paso3/matri-proceso-main-paso3.component';
import { MatriculaProcesoMainComponent } from './components/matricula-proceso-main/matricula-proceso-main.component';
import { MatListModule } from '@angular/material/list';


@NgModule({
  declarations: [
    MatriMainComponent,

    MatriEstudiantesListComponent,
    MatriEstudiantesMatriculadosListComponent,

    MatriProcesoMainComponent,


    MatriProcesoResumenMatriculaComponent,

    MatriProcesoMainPaso1Component,
    MatriProcesoMainPaso2Component,
    MatriProcesoMainPaso3Component,

    MatriculaProcesoMainComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModuloMatriculaRoutingModule,

    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatCardSubtitle,
    MatChipsModule,
    MatListModule,


    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatPaginatorModule,

    MatStepperModule,
    MatCheckboxModule,
    MatDivider,

    MatDatepickerModule,
    NativeDateModule,
    MatNativeDateModule,

    MatDialogModule,

    SvComponentsModule,

  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    provideNativeDateAdapter(),
  ],
})
export class ModuloMatriculaModule { }
