<div class="page-title">

  <div class="go-back">
    <button *ngIf="showGoBack" mat-stroked-button (click)="goBack()" class="text-button go-back-button">
      <mat-icon>arrow_back</mat-icon>
      <span>Retroceder</span>
    </button>
    <span *ngIf="!showGoBack" class="placeholder"></span>
  </div>

  <div class="title">{{ pageTitle }}</div>

  <div class="reload">
    <button *ngIf="showReload" mat-stroked-button (click)="reloadPage()" class="text-button reload-button">
      <mat-icon>refresh</mat-icon>
      Recargar
    </button>
    <span *ngIf="!showReload" class="placeholder"></span>
  </div>
</div>
