import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModuloContabilidadService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl;

  apiGetEstudiantes(pageIndex: number, pageSize: number, searchTerm: string): Observable<any> {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('pageSize', pageSize.toString())
      .set('search', searchTerm);

    return this.http.get<any>(`${this.apiUrl}/matricula/get-estudiantes`, { params });
  }

  // apiGetEstadoDeCuenta      = (params?: any)=> this.http.get(this.apiUrl+'/accounting/estado-financiero-estudiante', {params}).pipe(map((r: any) => r.data));
  apiGetEstadoDeCuenta      = (params?: any)=> this.http.get(this.apiUrl+'/accounting/estado-financiero-estudiante', {params});
  apiRealizarDeposito      = (params?: any)=> this.http.post(this.apiUrl+'/accounting/realizar-deposito', params);


  apiGetComprobantes      = (params?: any)=> this.http.get(this.apiUrl+'/accounting/comprobantes-sunat', {params}).pipe(map((r: any) => r.data));
  apiEnviarSunat = (id: any) => this.http.get(`${this.apiUrl}/sunat/enviar-xml-g/${id}`).pipe(map((r: any) => r.data));


  apiGetTransaccionesDeposito = (params?: any) => this.http.get(this.apiUrl + '/accounting/flujo-caja/transacciones-deposito', {params}).pipe(map((r: any) => r.data));
}
