<mat-card class="custom-card">
  <mat-card-header>
    <div class="header-content">

      <div class="title-group">
        <div class="title-text">{{titulo}}</div>
      </div>

      <button *ngIf="menuItems?.length" [matMenuTriggerFor]="menu" aria-label="Menú de opciones" class="menu-button">
        <mat-icon>more_vert</mat-icon>
        <mat-menu #menu="matMenu">
          <button *ngFor="let item of menuItems" mat-menu-item (click)="onMenuAction(item.action)">
            <mat-icon>{{item.icon}}</mat-icon>
            <span>{{item.label}}</span>
          </button>
        </mat-menu>
      </button>

    </div>
  </mat-card-header>

  <mat-card-content>
    <div *ngIf="codigo" class="code-text">Código: {{codigo}}</div>
    <div class="description-text">{{descripcion ?? 'Sin descripción'}}</div>

    <mat-chip-set *ngIf="featuresChips">
      <mat-chip *ngFor="let feature of featuresChips" class="custom-chip">Periodo: {{ feature }}</mat-chip>
      <!-- <mat-chip *ngIf="activo" class="custom-chip" [ngClass]="activo ? 'active-yes' : 'active-not'">{{ activo ? 'Activo' : 'Desactivado' }}</mat-chip> -->
    </mat-chip-set>

    <div *ngIf="descripcionArray" class="descripcion-list">
      <div *ngFor="let descripcion of descripcionArray" class="description-text"><b>{{descripcion.key}} :</b> {{descripcion.value}}</div>
    </div>

    <mat-chip-set *ngIf="featuresChipsArray">
      <mat-chip *ngFor="let feature of featuresChipsArray" class="custom-chip">{{feature.key}}: {{ feature.value }}</mat-chip>
    </mat-chip-set>

  </mat-card-content>
</mat-card>
