<div class="header-content mb-2">
  <div class="title-group">
    <div class="title-text">{{ relacion | titlecase }}</div>
  </div>
</div>
<mat-card *ngIf="personaForm">
  <mat-card-content>
    <form [formGroup]="personaForm" class="form-container" class="sv-mat-form-field-group">

      <div class="form-row">
        <!-- <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Nombress</mat-label>
          <input matInput formControlName="name">
        </mat-form-field> -->

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Nombres</mat-label>
          <input matInput formControlName="name" (keypress)="validateKeyPress($event)" required>
        </mat-form-field>


        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Apellido Paterno</mat-label>
          <input matInput formControlName="lastname_1" (keypress)="validateKeyPress($event)" required>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Apellido Materno</mat-label>
          <input matInput formControlName="lastname_2" (keypress)="validateKeyPress($event)" required>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Domicilio</mat-label>
          <input matInput formControlName="domicilio" required>
        </mat-form-field>

      </div>
      <div class="form-row">

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Celular</mat-label>
          <input matInput formControlName="celular" (keypress)="validateDniKeyPress($event)" maxlength="9" minlength="9" required>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" (keypress)="validateEmailKeyPress($event)" required>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Tipo de Documento</mat-label>
          <mat-select formControlName="tipo_documento" required>
            <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">{{ tipo.nombre }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Documento de Identidad</mat-label>
          <input matInput formControlName="documento_identidad" (keypress)="validateDniKeyPress($event)" maxlength="8" minlength="8" required>
        </mat-form-field>
      </div>

      <div class="bottom-buttons">
        <button
          *ngIf="relacion != 'estudiante'"
          class="bottom-button"
          mat-flat-button
          [ngClass]="personaForm.get('responsable')?.value ? 'es-responsable' : 'regular'"
          (click)="toggleResponsable()">
          {{ personaForm.get('responsable')?.value ? 'Responsable Seleccionado' : 'Seleccionar como Responsable' }}
        </button>
        <button
          *ngIf="relacion != 'estudiante'"
          class="bottom-button"
          mat-flat-button
          [ngClass]="personaForm.get('firmante')?.value ? 'es-firmante' : 'regular'"
          (click)="toggleFirmante()">
          {{ personaForm.get('firmante')?.value ? 'Firmante Seleccionado' : 'Seleccionar para firma' }}
        </button>
      </div>

    </form>
  </mat-card-content>
</mat-card>
