import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sv-no-data-boards',
  templateUrl: './sv-no-data-boards.component.html',
  styleUrl: './sv-no-data-boards.component.css'
})
export class SvNoDataBoardsComponent {

  @Input() message: string = 'No se encontraron resultados';
  @Input() height: any = '10rem';

}
