<mat-card class="custom-card">
  <mat-card-header>
    <div class="header-content">
      <div class="title-group">
        <div class="title-text">Depósito a la Cuenta del Estudiante</div>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content class="w-100">
    <form [formGroup]="depositoForm" class="row w-100">

      <div class="col">
        <div class="sv-label-form-group">

          <div class="sv-label-form-field">
            <mat-label>Monto</mat-label>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <span class="input-prefix" matTextPrefix>S/&nbsp;</span>
              <input matInput formControlName="monto">
            </mat-form-field>
          </div>

          <div class="sv-label-form-field">
            <mat-label>Tipo de Comprobante</mat-label>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <mat-select formControlName="tipo_comprobante" placeholder="Seleccione" required>
                <mat-option *ngFor="let tipo of tiposComprobante" [value]="tipo.codigo">{{ tipo.nombre }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="sv-label-form-field">
            <mat-label>Documento Cliente</mat-label>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <input matInput formControlName="nro_receptor" required>
            </mat-form-field>
          </div>

          <div class="sv-label-form-field">
            <mat-label>Tipo de Depósito</mat-label>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <mat-select formControlName="tipo_deposito" placeholder="Seleccione" required>
                <mat-option *ngFor="let tipo of tiposDeposito" [value]="tipo.codigo">{{ tipo.nombre }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="sv-label-form-field" *ngIf="depositoForm.get('tipo_deposito')?.value !== 'EFEC'">
            <mat-label>Número de Operación</mat-label>
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <input matInput formControlName="nro_operacion">
            </mat-form-field>
          </div>

          <!-- <div class="sv-label-form-field" *ngIf="depositoForm.get('tipo_deposito')?.value !== 'EFEC'">
            <mat-label>Comprobante de Operación</mat-label>
            <input type="file" (change)="onFileSelected($event)">
          </div> -->

          <!-- <div class="sv-label-form-field" *ngIf="depositoForm.get('tipo_deposito')?.value !== 'EFEC'">
            <mat-label>Comprobante de Operación</mat-label>
            <sv-file-uploader></sv-file-uploader>
          </div> -->

        </div>
      </div>

      <div class="col-5 d-flex flex-column vertical-align-center justify-content-center" *ngIf="depositoForm.get('tipo_deposito')?.value !== 'EFEC'">
        <div class="w-100">
          <sv-file-uploader
            (fileAdded)="onFileEvent($event)">
          </sv-file-uploader>
        </div>
      </div>



    </form>

    <div class="w-100 text-center align-items-center">

      <button *ngIf="xIdEstudiante && !depositoRealizado" mat-raised-button color="primary" [disabled]="depositoForm.invalid" (click)="depositarCuentaEstudiante()">Confirmar Depósito</button>
      <button *ngIf="xIdEstudiante && depositoRealizado" mat-raised-button color="primary" (click)="imprimirComprobante()">Imprimir Comprobante</button>
      <button *ngIf="depositoRealizado" mat-raised-button color="primary" (click)="activarDeposito()">Volver a depositar</button>

      <ng-container *ngIf="mostrarIndicacionesMatricula">
        <div class="mt-2" style="font-style: italic;" *ngIf="xIdEstudiante">*Si el saldo es insuficiente, es necesario confirmar el depósito primero luego se actualizará el saldo para proceder a previsualizar la matrícula </div>
        <div class="mt-2" style="font-style: italic;" *ngIf="!xIdEstudiante">*Al ser un estudiante nuevo, es necesario que el monto ingresado sea mayor a los cobros seleccionados. Al confirmar la matrícula se podrán descargar los comprobantes y el contrato. </div>
        <div class="mt-2" style="font-style: italic;" *ngIf="!xIdEstudiante">**Es importante que el primer depósito cubra el total, no es posible realizar 2 depósitos de matrícula para estudiantes nuevos </div>
      </ng-container>
    </div>

  </mat-card-content>

</mat-card>




<div #printSection_BoletaTicketera style="display: none;">
  <app-sv-pdf-ticket-deposito [title]="'Boleta de Venta'" [content]="'Contenido de la boleta...'"></app-sv-pdf-ticket-deposito>
</div>
