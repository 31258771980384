<!-- <div class="row gx-0"> -->
    <!-- <mat-card class="example-card m-2">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Portal Administración</mat-card-title>
        <mat-card-subtitle>Admin, Director, Secretaria</mat-card-subtitle>
      </mat-card-header>
      <img
        mat-card-image
        src="https://material.angular.io/assets/img/examples/shiba2.jpg"
        alt="Photo of a Shiba Inu"
      />
      <mat-card-content>
        <p style="margin-top: 16px">
          En el portal se configura toda la estructura de la institución como
          SEDES, GRADOS, ALMACÉN entre otros.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>INGRESAR</button>
      </mat-card-actions>
    </mat-card> -->



    <!-- <mat-card *ngFor="let modulo of listaModulos" class="example-card m-2"> -->
      <!-- <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{modulo.nombre}}</mat-card-title>
        <mat-card-subtitle>Aaaaa</mat-card-subtitle>
      </mat-card-header> -->

      <!-- <div style="text-align: center; padding: 16px 8px; font-weight: bold; font-size: 16px;"> -->
        <!-- {{modulo.nombre}} -->
      <!-- </div> -->

      <!-- <img
        mat-card-image
        src="https://material.angular.io/assets/img/examples/shiba2.jpg"
        alt="Photo of a Shiba Inu"
      /> -->
      <!-- <div class="image-container"> -->
        <!-- <img style="height: 200px; width: 100%;" -->
          <!-- mat-card-image -->
          <!-- [src]="modulo.image_url ?? 'assets/home_modulo_administracion.png'" -->
          <!-- alt="Photo of a Shiba Inu" -->
        <!-- /> -->
      <!-- </div> -->
      <!-- <mat-card-content>
        <p style="margin-top: 16px">
          TEST 2
        </p>
      </mat-card-content> -->

      <!-- <mat-card-actions> -->
        <!-- <button mat-button [routerLink]="[modulo.url]">INGRESAR</button> -->
        <!-- <button mat-button [routerLink]="['/modulos', modulo.url]">INGRESAR</button> -->
        <!-- <button mat-button (click)="navigateToModule(modulo.id, modulo.url)">INGRESAR</button> -->
      <!-- </mat-card-actions> -->


      <!-- <button mat-button class="ingresar-button" (click)="navigateToModule(modulo.id, modulo.url)">Ingresar</button> -->
    <!-- </mat-card> -->

<!-- </div> -->





<div class="row gx-0 h-100">

  <div class="col-5" style="background-image: url('assets/home_ main_colegio.png'); background-size: cover; background-position: center; background-repeat: no-repeat; background-attachment: local;">

    <div class="toolbar-v2-user">
      {{usuario.name ?? ''}} {{usuario.name_2 ?? ''}} {{usuario.lastname_1 ?? ''}} {{usuario.lastname_2 ?? ''}}
      <button class="btn-status btn-info" (click)="cerrarSesion()">Cerrar Sesión</button>
    </div>

  </div>
  <div class="col-7 p-5 right-column">

    <div *ngIf="listaModulos.length" class="d-flex flex-column align-items-center justify-content-center">
      <img src="assets/Logo-SJ.png" alt="" style="width: 300px;">

      <!-- <div class="w-100" style="font-size: 32px; margin: 50px 10px; text-align: center;">
        Seleccione el módulo al que desea ingresar
      </div> -->

      <div class="grid-container w-100 mt-5">
        <mat-card *ngFor="let modulo of listaModulos" class="example-card m-2">
          <div style="text-align: center; padding: 16px 8px; font-weight: bold; font-size: 16px;">
            {{modulo.nombre}}
          </div>
          <div class="image-container">
            <img style="height: 150px; width: 100%;"
              mat-card-image
              [src]="modulo.image_url ?? 'assets/home_modulo_administracion.jpg'"
              alt="Portada del Módulo"
            />
          </div>
          <button mat-button class="ingresar-button" (click)="navigateToModule(modulo.id, modulo.url, modulo.nombre)">Ingresar</button>
        </mat-card>
      </div>
    </div>

  </div>

</div>


