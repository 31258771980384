import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'sv-page-title',
  templateUrl: './sv-page-title.component.html',
  styleUrl: './sv-page-title.component.css'
})
export class SvPageTitleComponent {

  @Input() pageTitle  : string = '';
  @Input() showGoBack : boolean = false;
  @Input() showReload : boolean = false;

  constructor(
    private location: Location
  ) {}


  goBack(): void {
    this.location.back();
  }

  reloadPage(): void {
    window.location.reload();
  }


}
