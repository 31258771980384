<!-- <body>
  <div class="img-container">

  </div>
  <div class="login-container">
    <img src="../../assets/LOGO.webp" alt="">



    <div>
      Iniciar Sesión
    </div>


    <form>
      <div *ngFor="let field of formLogin" class="form-group">

        <label class="label" [for]="field.name"></label>
        <input class="input" [type]="field.type" [name]="field.name" [placeholder]="field.placeholder"
               [(ngModel)]="formDataLogin[field.name]">
      </div>

      <button type="submit" (click)="clickLogin()">Ingresar</button>
    </form>
  </div>
</body> -->





<div class="container-qqq">

  <div class="row card-container text-center" style="background-color: white; border-radius: 1rem; padding: 1rem;">

    <div style="font-size: 1.5rem; margin: 1rem 0;"><b>Inicio de Sesión</b></div>

    <div style="height: 1px; width: 100%; background-color: #e4ebf5;"></div>

    <div class="col left-card" style="display: flex; align-items: center; justify-content: center;">
      <img src="../../assets/LOGO.webp" alt="" style="height: 10rem; width: 9rem;">
    </div>

    <div class="col right-card">

      <form style="text-align: center;">
          <div *ngFor="let field of formLogin" class="form-group">
            <div style="text-align: center; margin: 1rem 0 0.25rem;">{{field.name}}</div>
            <input class="input" [type]="field.type" [name]="field.name" [placeholder]="field.placeholder"
                    [(ngModel)]="formDataLogin[field.name]">
          </div>

          <button type="submit" style="margin-top: 1rem;" (click)="clickLogin()">Ingresar</button>
        </form>
    </div>

  </div>

</div>
