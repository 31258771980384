import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sv-skeleton-card-list',
  templateUrl: './sv-skeleton-card-list.component.html',
  styleUrl: './sv-skeleton-card-list.component.css'
})
export class SvSkeletonCardListComponent {

  @Input() numberCards    : number  = 1;
  @Input() numberChips    : number  = 5;
  @Input() numberChildren : number  = 1;
  @Input() childrenCircle : boolean = false;

}
