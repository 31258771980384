import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MatEstudiantesMatriculaService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl + '/matricula/estudiantes/matricula';

  apiGetEstudiantes = (params?: any)  => this.http.get    (`${this.apiUrl}/get-estudiantes`, {params});

}
