import { Component, Input, HostListener, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-layout-column-row',
  templateUrl: './layout-column-row.component.html',
  styleUrls: ['./layout-column-row.component.css']
})
export class LayoutColumnRowComponent implements AfterViewInit, OnChanges {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;

  @Input() xAxis: string[] = [];
  @Input() yAxis: string[] = [];
  @Input() group: any[] = [];

  chartInstance!: echarts.ECharts;
  chartOptions: echarts.EChartsOption = {};

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initChart();
      this.resizeChart();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.chartInstance && this.xAxis && this.group) {
      this.generateChartOptions();
      this.chartInstance.setOption(this.chartOptions, true);
    }
  }

  generateChartOptions(): void {
    const datasetSource = [
      ['Fecha', ...this.xAxis]
    ];

    for (let i = 0; i < this.yAxis?.length; i++) {
      const secretariaRow = [this.yAxis[i], ...this.group.map(serie => serie.data[i] || 0)];
      datasetSource.push(secretariaRow);
    }

    console.log('datasetSource: ', datasetSource);

    const seriesConfig: echarts.SeriesOption[] = [];

    for (let i = 0; i < this.yAxis?.length; i++) {
      seriesConfig.push({ type: 'bar', seriesLayoutBy: 'row', xAxisIndex: 0, yAxisIndex: 0 });
    }
    for (let i = 0; i < this.xAxis?.length; i++) {
      seriesConfig.push({ type: 'bar', xAxisIndex: 1, yAxisIndex: 1 });
    }

    console.log('seriesConfig: ', seriesConfig);

    this.chartOptions = {
      tooltip: {},
      dataset: {
        source: datasetSource
      },
      grid: [
        { bottom: '55%' },
        { top: '55%' }
      ],
      xAxis: [
        { type: 'category', gridIndex: 0 },
        { type: 'category', gridIndex: 1 }
      ],
      yAxis: [
        { gridIndex: 0 },
        { gridIndex: 1 }
      ],
      series: seriesConfig
    };
  }

  initChart(): void {
    this.chartInstance = echarts.init(this.chartContainer.nativeElement, undefined, {
      renderer: 'svg',
    });

    this.generateChartOptions();
    this.chartInstance.setOption(this.chartOptions, true);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeChart();
  }

  resizeChart(): void {
    if (this.chartInstance) {
      this.chartInstance.resize();
    }
  }
}
