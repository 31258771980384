import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdministrationSharedService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl + '/administration/shared';

  apiGetSedesNivelesGrados  = (params?: any)  => this.http.get(this.apiUrl + '/sedes-niveles-grados', {params});
  apiGetTipoPlanes          = (params?: any)  => this.http.get(this.apiUrl + '/tipo-planes', {params});
  apiGetPeriodos            = (params?: any)  => this.http.get(this.apiUrl + '/periodos', {params});

  apiGetPeriodosPlanTipos     = (params?: any)  => this.http.get(this.apiUrl + '/periodos-plan-tipos', {params});

}
