<div class="esv-page">
  <div class="page-title">
    Comprobantes para Sunat
  </div>

  <div class="body">

    <table mat-table [dataSource]="listaComprobantes" class="mat-elevation-z0">

      <ng-container matColumnDef="cod_comprobante">
        <th mat-header-cell *matHeaderCellDef> Código de Comprobante </th>
        <td mat-cell *matCellDef="let element"> {{element.cod_comprobante}} </td>
      </ng-container>

      <ng-container matColumnDef="dni_ruc_receptor">
        <th mat-header-cell *matHeaderCellDef> DNI/RUC de Receptor </th>
        <td mat-cell *matCellDef="let element"> {{element.dni_ruc_receptor}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="xml_firmado">
        <th mat-header-cell *matHeaderCellDef> XML Firmado </th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="!element.xml_firmado" mat-raised-button color="primary" (click)="firmarXml(element)">Firmar XML</button>
          <mat-icon *ngIf="element.xml_firmado">check</mat-icon>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="sunat_confirmado">
        <th mat-header-cell *matHeaderCellDef> Confirmado por Sunat </th>
        <td mat-cell *matCellDef="let element">
          <!-- <button *ngIf="element.xml_firmado && !element.sunat_confirmado" mat-raised-button color="accent" (click)="enviarASunat(element)">Enviar a Sunat</button> -->
          <button *ngIf="!element.sunat_confirmado" mat-raised-button color="accent" (click)="enviarASunat(element)">Enviar a Sunat</button>
          <mat-icon *ngIf="element.sunat_confirmado">check</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

  </div>

</div>
