import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sv-pdf-ticket-deposito',
  templateUrl: './sv-pdf-ticket-deposito.component.html',
  styleUrl: './sv-pdf-ticket-deposito.component.css'
})
export class SvPdfTicketDepositoComponent {

  @Input() title: string = "";
  @Input() content: string = "";

  fechaHoraActual: Date;

  constructor() {
    this.fechaHoraActual = new Date();
  }

  ngOnInit(): void {
    setInterval(() => {
      this.fechaHoraActual = new Date();
    }, 1000);
  }

}
