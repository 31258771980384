import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sv-card-matricula-persona',
  templateUrl: './sv-card-matricula-persona.component.html',
  styleUrl: './sv-card-matricula-persona.component.css'
})
export class SvCardMatriculaPersonaComponent {


  // @Input() nombre_formulario!: any[];
  // @Input() tiposDocumento!: any[];
  // @Input() personaForm!: FormGroup;

  @Input() personaForm!: FormGroup;
  @Input() relacion!: string;
  // @Input() tiposDocumento: any[] = [];
  // @Input() puedeQuitar: boolean = false;
  @Input() puedeAgregar: boolean = false;

  // Eventos para agregar o quitar familiar
  @Output() onRemovePersona: EventEmitter<any> = new EventEmitter<any>();

  @Output() onSeleccionarResponsable: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSeleccionarFirmante: EventEmitter<any> = new EventEmitter<any>();


  tiposDocumento: any[] = [
    { id: 1, nombre: 'DNI' },
    { id: 2, nombre: 'CE' },
  ];

  ngOnInit(): void {
    const responsable = this.personaForm.get('responsable')?.value;
    if (responsable) this.onSeleccionarResponsable.emit();

    const firmante = this.personaForm.get('firmante')?.value;
    if (firmante) this.onSeleccionarFirmante.emit();
  }

  removePersona() {
    this.onRemovePersona.emit();
  }

  toggleResponsable() {
    const responsable = this.personaForm.get('responsable')?.value;
    this.personaForm.get('responsable')?.setValue(!responsable);
    this.onSeleccionarResponsable.emit();
  }

  toggleFirmante() {
    const responsable = this.personaForm.get('firmante')?.value;
    this.personaForm.get('firmante')?.setValue(!responsable);
    this.onSeleccionarFirmante.emit();
  }
// ......................
  validateKeyPress(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.keyCode);
    if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateDniKeyPress(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.keyCode);
    if (!/^[0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateEmailKeyPress(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.keyCode);
    if (!/^[a-zA-Z0-9@._-]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

}
