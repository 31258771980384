import { Component, Input, HostListener, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-basic-bars',
  templateUrl: './basic-bars.component.html',
  styleUrls: ['./basic-bars.component.css']
})
export class BasicBarsComponent implements AfterViewInit, OnChanges {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;

  @Input() xAxis: string[] = [];
  @Input() series: number[] = [];

  chartInstance!: echarts.ECharts;
  chartOptions: echarts.EChartsOption = {};


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initChart();
      this.resizeChart();
    }, 0);
  }

  // Detectar cambios en los inputs
  ngOnChanges(changes: SimpleChanges): void {
    if (this.chartInstance) {
      this.chartInstance.setOption({
        xAxis: {
          type: 'category',
          data: this.xAxis
        },
        series: [{
          data: this.series,
          type: 'bar'
        }]
      });
    }
  }

  initChart(): void {
    this.chartOptions = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: this.xAxis,
        axisTick: { show: false }
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: this.series,
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            // formatter: (params: any) => this.xAxis[params.dataIndex],
            formatter: (params: any) => 'S/ ' + params.value,
            fontSize: 14
          },
          itemStyle: {
            color: (params: any) => {
              const colors = ['#5470C6', '#91CC75', '#EE6666', '#FAC858', '#73C0DE', '#3BA272', '#FC8452'];
              return colors[params.dataIndex % colors.length]; // Color diferente para cada barra
            }
          }
        }
      ]
    };

    this.chartInstance = echarts.init(this.chartContainer.nativeElement, undefined, {
      renderer: 'svg',
    });

    this.chartInstance.setOption(this.chartOptions);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeChart();
  }

  resizeChart(): void {
    if (this.chartInstance) {
      this.chartInstance.resize();
    }
  }
}
