export const environment = {
  production: false,
  apiUrl: 'https://dev.portal-back.sjj.com.pe/api'
};
// export const environment = {
//   production: false,
//   apiUrl: 'http://localhost:8000/api'
//   // apiUrl: 'http://192.168.18.21:8000/api'
//   // apiUrl: `${window.location.origin}/api`
// };
