<mat-toolbar color="primary" class="toolbar">
  <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
      <mat-icon>menu</mat-icon>
  </button>

  <div class="toolbar-center">
      <img src="assets/LOGO-BLANCO.png" alt="Logo" class="toolbar-logo" routerLink="/modulos">
  </div>

  <span class="toolbar-item-spacer"></span>

  <button mat-icon-button matTooltip="Notificaciones">
      <mat-icon>notifications</mat-icon>
  </button>
  <button mat-icon-button matTooltip="Mi perfil" [matMenuTriggerFor]="userAccountMenu">
      <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
      <button mat-menu-item routerLink="#">
          <mat-icon>person</mat-icon><span>Mi perfil</span>
      </button>
      <button mat-menu-item routerLink="#">
          <mat-icon>settings</mat-icon><span>Ajustes</span>
      </button>
      <mat-divider></mat-divider>
    <button mat-menu-item routerLink="#">
        <mat-icon>exit_to_app</mat-icon>Cerrar Sesión
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav mode="side" class="app-sidenav" [opened]="true">

    <div class="sidenav-name">
      {{moduloParent?.nombre ?? ''}}
    </div>
    <div style="width: 256px; height: 1px; background-color: #cccccc; margin: 2rem auto;"></div>

    <div class="new-sidenav-list">
      <div *ngFor="let modulo of listaModulos" class="new-sidenav-list-item" [ngClass]="{'selected': isModuloSeleccionado(modulo)}">
        <div class="new-sidenav-list-item-parent" [routerLink]="modulo.url" routerLinkActive="active-link" (click)="seleccionarModulo(modulo)" [ngClass]="{'parent-selected': isModuloSeleccionado(modulo)}">
          <mat-icon>check</mat-icon>
          <span>{{modulo.nombre}}</span>
        </div>
        <div *ngFor="let moduloChild of modulo.hijos" class="new-sidenav-list-item-child" [ngClass]="{'selected': isModuloSeleccionado(moduloChild)}" (click)="seleccionarModulo(moduloChild)" [routerLink]="moduloChild.url" routerLinkActive="active-link">
          <mat-icon style="transform: scale(0.8);">check</mat-icon>
          <span>{{moduloChild.nombre}}</span>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="app-sidenav-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
