import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sv-card-list',
  templateUrl: './sv-card-list.component.html',
  styleUrl: './sv-card-list.component.css'
})
export class SvCardListComponent {
  @Input() titulo               : string      = '';
  @Input() codigo?              : string      = '';
  @Input() descripcion?         : string      = '';
  @Input() menuItems?           : MenuItem[]  = [];
  @Input() activo?              : boolean     = false;
  @Input() featuresChips?       : string[]    = [];
  @Input() featuresChipsArray?  : Array<{ key: string; value: string; }> = [];
  @Input() descripcionArray?    : Array<{ key: string; value: string; }> = [];

  @Output() actionClicked: EventEmitter<string> = new EventEmitter<string>();

  onMenuAction(action: string) {
    this.actionClicked.emit(action);
  }

}

interface MenuItem {
  icon:   string;
  label:  string;
  action: string;
}
