import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModuloAdministracionService } from 'src/app/pages/modulo-administracion/modulo-administracion.service';

@Component({
  selector: 'app-reporte-mensualidades',
  templateUrl: './reporte-mensualidades.component.html',
  styleUrl: './reporte-mensualidades.component.css'
})
export class ReporteMensualidadesComponent {

  listPlanTipos:  any[] = [];
  listPeriodos:   any[] = [];
  listSedes:      any[] = [];
  listNiveles:    any[] = [];
  listGrados:     any[] = [];
  listGrupos:     any[] = [];

  selectedPlanTipo: number | string = '';
  selectedPeriodo:  number | string = '';
  selectedSede:     number | string = '';
  selectedNivel:    number | string = '';
  selectedGrado:    number | string = '';
  selectedGrupo:    number | string = '';

  constructor(
    private moduloAdministracionService: ModuloAdministracionService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getTipoPlanes();
    this.getPeriodos();
    this.getSedesNivelesGrados();
  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  getTipoPlanes() {
    this.moduloAdministracionService.getTipoPlanes()
      .subscribe((data: any[]) => {
        this.listPlanTipos = data;
      });
  }

  getPeriodos() {
    let params = { 'activo': true };
    this.moduloAdministracionService.apiGetPeriodos(params)
    .subscribe((data: any[]) => {
      this.listPeriodos = data;
    });
  }

  getSedesNivelesGrados() {
    let params = { };
    this.moduloAdministracionService.apiGetSedesNivelesGrados(params)
    .subscribe((data: any[]) => {
      console.log('sedes: ', data);
      this.listSedes = data;
    });
  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  onSedeChange() {
    this.selectedNivel = '';
    this.selectedGrado = '';
    this.setNiveles();
    this.getReporte();
  }

  onNivelChange() {
    this.selectedGrado = '';
    this.setGrados();
    this.getReporte();
  }

  applyFilters() {
    this.getReporte();
  }

  setNiveles() {
    if (this.selectedSede) {
      let sede = this.listSedes.find(n => n.id === this.selectedSede);
      if (sede) {
        this.listNiveles = sede.niveles;
      }
    } else {
      this.listNiveles = [];
    }
    this.listGrados = [];
  }

  setGrados() {
    if (this.selectedNivel) {
      let nivel = this.listNiveles.find(n => n.id === this.selectedNivel);
      if (nivel) {
        this.listGrados = nivel.grados;
      }
    } else {
      this.listGrados = [];
    }
  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  getReporte(){}

}
