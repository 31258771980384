



<div class="esv-page">
  <div class="page-title">
    Reporte del Flujo de Caja
  </div>
  <div class="header">
    <div class="element-section">
      <div class="element-section-title">Reporte de Depósitos</div>
    </div>

  </div>

  <div class="body">

    <div class="row">
      <div class="col-md-9">

        <!-- <app-bar-label-rotation></app-bar-label-rotation>

        <app-layout-column-row></app-layout-column-row> -->

        <app-basic-bars
          *ngIf="tipoVistaCalendario === 'dia'"
          [xAxis]="transaccionesData?.xAxis"
          [series]="transaccionesData?.series">
        </app-basic-bars>

        <app-layout-column-row
          *ngIf="tipoVistaCalendario === 'intervalo'"
            [xAxis]="transaccionesData?.xAxis"
            [yAxis]="transaccionesData?.yAxis"
            [group]="transaccionesData?.group">
        </app-layout-column-row>


      </div>
      <div class="col-md-3">

        <div class="button-group mb-4">
          <div class="slider"></div>
          <button mat-button
                  color="primary"
                  [ngClass]="{ 'active': tipoVistaCalendario === 'dia' }"
                  (click)="cambiarVistaCalendarioPicker('dia')">Día</button>
          <button mat-button
                  color="primary"
                  [ngClass]="{ 'active': tipoVistaCalendario === 'intervalo' }"
                  (click)="cambiarVistaCalendarioPicker('intervalo')">Intervalo</button>
        </div>







        <div *ngIf="tipoVistaCalendario === 'dia'" class="calendar-wrapper">

          <mat-card class="inline-calendar-card">
            <mat-calendar [(selected)]="selected" (selectedChange)="getTransaccionesDeposito()"></mat-calendar>
          </mat-card>
          <p>Fecha Seleccionada: {{selected | date}}</p>



        </div>




        <div *ngIf="tipoVistaCalendario === 'intervalo'" class="calendar-wrapper">
          <mat-calendar class="text-center inline-calendar-card"
            [selected]="selectedRangeValue"
            (selectedChange)="selectedChange($event)">
          </mat-calendar>
        </div>

        <button
          mat-button
          (click)="getTransaccionesDeposito()"
          [disabled]="!isReadyToLoad()">Cargar Datos</button>




      </div>
    </div>

  </div>

</div>
