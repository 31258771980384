import { Component, OnInit, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-bar-label-rotation',
  templateUrl: './bar-label-rotation.component.html',
  styleUrls: ['./bar-label-rotation.component.css']
})
export class BarLabelRotationComponent implements OnInit, AfterViewInit {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;

  chartInstance!: echarts.ECharts;
  chartOptions: echarts.EChartsOption = {};

  ngOnInit(): void {
    this.initChart();
  }

  ngAfterViewInit(): void {
    this.resizeChart();
  }

  initChart(): void {
    const posList = [
      'left', 'right', 'top', 'bottom', 'inside', 'insideTop', 'insideLeft',
      'insideRight', 'insideBottom', 'insideTopLeft', 'insideTopRight',
      'insideBottomLeft', 'insideBottomRight'
    ];

    const labelOption: echarts.BarSeriesOption['label'] = {
      show: true,
      position: 'insideBottom',
      distance: 15,
      align: 'left',
      verticalAlign: 'middle',
      rotate: 90,
      formatter: '{c}  {name|{a}}',
      fontSize: 16,
      rich: {
        name: {}
      }
    };

    this.chartOptions = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['Forest', 'Steppe', 'Desert', 'Wetland']
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: ['2012', '2013', '2014', '2015', '2016']
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Forest',
          type: 'bar',
          barGap: 0,
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [320, 332, 301, 334, 390]
        },
        {
          name: 'Steppe',
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [220, 182, 191, 234, 290]
        },
        {
          name: 'Desert',
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [150, 232, 201, 154, 190]
        },
        {
          name: 'Wetland',
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [98, 77, 101, 99, 40]
        }
      ]
    };

    this.chartInstance = echarts.init(this.chartContainer.nativeElement, undefined, {
      renderer: 'svg',
    });

    this.chartInstance.setOption(this.chartOptions);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeChart();
  }

  resizeChart(): void {
    if (this.chartInstance) {
      this.chartInstance.resize();
    }
  }







}
