<div class="invoice-wrapper" id="print-area">
  <div class="invoice">
    <div class="invoice-container">
      <div class="invoice-head">
        <div class="invoice-head-top">
          <div class="invoice-head-top-left text-start">
            <img src="/assets/Logo-SJ.png" alt="Logo" />
          </div>
          <div class="invoice-head-top-right text-center">
            <h3>Boleta de Pago</h3>
            <h5>R.U.C. 20123456789</h5>
            <h5>Serie o Numero</h5>
          </div>
        </div>
        <!-- <div class="hr"></div>
        <div class="invoice-head-middle">
          <div class="invoice-head-middle-left text-start">
            <p><span class="text-bold">Date:</span> 05/12/2020</p>
          </div>
          <div class="invoice-head-middle-right text-end">
            <p><span class="text-bold">Invoice No:</span> 16789</p>
          </div>
        </div> -->
        <div class="hr"></div>
        <div class="invoice-head-bottom">
          <div class="invoice-head-bottom-left">
            <ul>
              <li class="text-bold">COLEGIO SAN JOSE JULIACA</li>
              <li>Jr. Gonzales Prada 433</li>
              <li>JULIACA-JULIACA-PUNO </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="overflow-view">
        <div class="invoice-body">

          <table>
            <tbody>
              <tr>
                <td>
                    <ul>
                      <li>Fecha de Emision: </li>
                      <li>Señor(es):</li>
                      <li>Direccion:</li>
                      <li>Celular:</li>
                      <li>Tipo de Pago:</li>
                      <li>Fecha de Vencimiento:</li>
                    </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="overflow-view">
        <div class="invoice-body">
          <table>
            <thead>
              <tr>
                <td>Nombre</td>
                <td>Tipo</td>
                <td>Monto Inicial</td>
                <td>Monto final</td>
                <td>Fecha de Pago inicial</td>
                <td>Fecha de Pago final</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>M1</td>
                <td>WMatricula</td>
                <td>S/. </td>
                <td>S/. </td>
                <td>10/11/2024</td>
                <td>10/11/2024</td>
              </tr>
            </tbody>
          </table>
          <div class="invoice-body-bottom">
            <div class="invoice-body-info-item border-bottom">
              <div class="info-item-td text-end ">Total a Pagar:</div>
              <div class="info-item-td text-end">$1500.00</div>
            </div>
            <div class="invoice-body-info-item border-bottom">
              <div class="info-item-td text-end ">Saldo Actual:</div>
              <div class="info-item-td text-end">$150.00</div>
            </div>
            <div class="invoice-body-info-item">
              <div class="info-item-td text-end ">Saldo a Favor:</div>
              <div class="info-item-td text-end">$1650.00</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="invoice-foot text-center">
        <p><span class="text-bold text-center">NOTE:</span> This is a computer-generated receipt and does not require a physical signature.</p>
      </div> -->
    </div>
  </div>
</div>







