import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, model, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { DateRange } from '@angular/material/datepicker';
import { ModuloContabilidadService } from '../../modulo-contabilidad.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-flujo-caja',
  templateUrl: './flujo-caja.component.html',
  styleUrl: './flujo-caja.component.css',

  providers: [provideNativeDateAdapter()],

  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FlujoCajaComponent {


  @Input() selectedRangeValue: DateRange<Date> | undefined;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();

  @Output() datosActualizados: EventEmitter<any> = new EventEmitter();


  today = new Date();
  selected: Date | null = this.today;

  transaccionesData: any;

  startDate: Date | null = null;
  endDate: Date | null = null;

  tipoVistaCalendario: string = 'dia';

  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private moduloContabilidadService: ModuloContabilidadService,
    private cdr: ChangeDetectorRef,
    private dateAdapter: DateAdapter<Date>
  ) {}

  ngOnInit(){
    this.getTransaccionesDeposito();
  }

  getTransaccionesDeposito() {

    if (this.isLoading) return;

    let params: any = {};

    if (this.tipoVistaCalendario === 'dia') {
      // Consulta por día
      const selectedDate = this.selected ? formatDate(this.selected, 'yyyy-MM-dd', 'en') : null;
      params = {
        date: selectedDate,
      };
    } else if (this.tipoVistaCalendario === 'intervalo') {
      // Consulta por rango de fechas
      // const startDate = this.startDate ? formatDate(this.startDate, 'yyyy-MM-dd', 'en') : null;
      // const endDate = this.endDate ? formatDate(this.endDate, 'yyyy-MM-dd', 'en') : null;
      const startDate = this.selectedRangeValue?.start ? formatDate(this.selectedRangeValue?.start, 'yyyy-MM-dd', 'en') : null;
      const endDate = this.selectedRangeValue?.end ? formatDate(this.selectedRangeValue?.end, 'yyyy-MM-dd', 'en') : null;
      params = {
        start_date: startDate,
        end_date: endDate,
        // month: null,
        // year: null
      };
    }

    this.isLoading = true;

    this.moduloContabilidadService.apiGetTransaccionesDeposito(params)
      .subscribe((data: any) => {
        this.transaccionesData = data;
        this.cdr.markForCheck();
        this.datosActualizados.emit(this.transaccionesData);
        this.isLoading = false;
      });
  }


  cambiarVistaCalendarioPicker(tipoVista: string){
    this.tipoVistaCalendario = tipoVista;
    if (this.tipoVistaCalendario === 'dia'){
      this.selected = this.today;
    } else {
      const start = this.dateAdapter.addCalendarDays(this.today, -3);
      const end = this.dateAdapter.addCalendarDays(this.today, 3);
      this.selectedRangeValue = new DateRange<Date>(start, end);
    }
    this.getTransaccionesDeposito();
  }




  selectedChange(m: any) {
      if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
          this.selectedRangeValue = new DateRange<Date>(m, null);
      } else {
          const start = this.selectedRangeValue.start;
          const end = m;
          if (end < start) {
              this.selectedRangeValue = new DateRange<Date>(end, start);
          } else {
              this.selectedRangeValue = new DateRange<Date>(start, end);
          }
      }
      this.selectedRangeValueChange.emit(this.selectedRangeValue);
  }

  isReadyToLoad(): boolean {
    if (this.tipoVistaCalendario === 'dia') {
      return !!this.selected; // Verifica si hay una fecha seleccionada para "día"
    } else if (this.tipoVistaCalendario === 'intervalo') {
      return !!(this.selectedRangeValue?.start && this.selectedRangeValue?.end); // Verifica si ambas fechas están seleccionadas para el rango
    }
    return false;
  }











}
